import { ServiceSearchData } from '../@types';
import { queryOptionType, TemplateRowDataType } from '../@types/interactions.type';
import { httpClient, HttpMethod } from './http-client';

export const getAllInteractionTemplates = (queryOptions: queryOptionType) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  
  let url = `/interactionTemplates?page=${page + 1}&limit=${pageSize}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel?.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });
  
  return httpClient(url, HttpMethod.Get, null);
};

export const createInteractionTemplate = (templateData: TemplateRowDataType) => {
  return httpClient('/interactionTemplates', HttpMethod.Post, templateData);
};

export const updateInteractionTemplate = (id: number, updatedTemplateData: TemplateRowDataType) => {
  return httpClient(`/interactionTemplates/${id}`, HttpMethod.Put, updatedTemplateData);
};

export const getAllInteractionsRulesConfigs = (queryOptions: any) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/interactionRules?limit=${pageSize}&page=${page+1}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const submitFormsDataWithOriginalPayload = (url: string, method: HttpMethod, payload: any) => {
  return httpClient(url, method, payload);
};

export const getInteractionsPartRequestFormPartTypes = (serviceId: number) => {
  return httpClient(`/partTypes/service/${serviceId}`, HttpMethod.Get, null);
};

export const getInteractionsPartRequestFormPartTypesChildrenWithParent = (partTypeId: number) => {
  return httpClient(`/partType/childrenWithParent/${partTypeId}`, HttpMethod.Get, null);
};

export const getInteractionsPartRequestFormPartQuantities = (partTypeId: number, serviceId: number, serialised: boolean) => {
  return httpClient(`/getPartQuantitiesBy/partType/${partTypeId}/partCondition/good/service/${serviceId}?isSerialized=${serialised}`, HttpMethod.Get, null);
};

export const getAllActiveInteractionTemplates = () => {
  return httpClient('/activeInteractionTemplates', HttpMethod.Get, null);
};

export const getParameterData = (serviceId: number, selectedParameter: string, mainLocationNodeId: number, subLocationNodeId: number, clientId: number) => {
  return httpClient(`/getInteractionParameterData?serviceId=${serviceId}&selectedParameter=${selectedParameter}&mainLocationNodeId=${mainLocationNodeId}&subLocationNodeId=${subLocationNodeId}&clientId=${clientId}`, HttpMethod.Get, null);
};

export const getServiceSearchData = (serviceId: number, serviceSearchData: ServiceSearchData) => {
  return httpClient('/interactions/services/searchData', HttpMethod.Post, { serviceId, serviceSearchData });
};

export const downloadNmiCertificate = (data: { serviceId: number; serviceLogId: number }) => {
  return httpClient('/interactions/nmi/download', HttpMethod.Post, data);
};

export const editInteraction = (data: { serviceId: number; serviceLogId: number; interactionForm: any }) => {
  return httpClient('/interaction/log', HttpMethod.Put, data);
};

export const modifyStructuresToggleActive = (data: { serviceMainLocationNodeId: number; serviceSubLocationNodeId?: number; toggleActiveType: string }) => {
  return httpClient('/modifyStructuresToggleActive', HttpMethod.Put, data);
};