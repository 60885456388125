import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Tooltip } from '@material-ui/core';
import { ColorDot } from '../../atoms/ColorDot';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { EditField } from '../../molecules/EditField';
import { EditDropdownField } from '../../molecules/EditDropdownField';
import { EditDateTimePicker } from '../../molecules/EditDateTimePicker';
import { EditPrioritySelectMenu } from '../../organisms/EditPrioritySelectMenu';
import { ServiceSummaryProps } from './ServiceSummary.props';
import { GridAlignContainer, TitleLabel } from './ServiceSummary.styles';
import { EditLiveSearchField } from '../../molecules/EditLiveSearchField/EditLiveSearchField';
import { LocationNodeData, ScreenSize } from '../../../@types';
import { COLORS } from '../../../configs/colors';
import { actionTypesData, processTypesData, serviceTypesData } from '../../../utils/services';
import { SERVICE_PRESENTATION_OPTIONS } from '../../../constants/interaction.constant';

export const ServiceSummary: React.FC<ServiceSummaryProps> = ({
  permissions,
  options,
  value,
  onChange
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  useEffect(() => {
    setIsMobile(isMobileView);
  }, [isMobileView]);

  return (
    <Grid container mt={2}>
      <Grid item xs md={4} mb={0.2}>
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Client / Contract</TitleLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <Tooltip title={`${value?.client}${value?.contractName && `/${value?.contractName}`}`} placement="top-start">
              <span>
                <Typography variant={TypographyVariantProps.Body1}>{value.clientCode || ''} / {value.contractCode || ''}</Typography>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        {!isMobile ? 
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Customer ref</TitleLabel>
            </Grid>
            <GridAlignContainer item xs={12} md={6}>
              <EditField
                isEditable={permissions.isReferenceNo1}
                value={value.referenceNo1 || ''}
                onChange={(val) => onChange({
                  ...value,
                  referenceNo1: val
                })}
              />
            </GridAlignContainer>
          </Grid>
          :
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Brand</TitleLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <EditField
                isEditable={permissions.isBrand}
                value={value.brand || ''}
                onChange={(val) => onChange({
                  ...value,
                  brand: val
                })}
              />
            </Grid>
          </Grid>
        }
        {!isMobile ?
          <>
            <Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Logged at</TitleLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <EditDateTimePicker
                  isEditable={permissions.isLoggedAt}
                  value={value.loggedAt || ''}
                  onChange={(val) => onChange({
                    ...value,
                    loggedAt: val
                  })} />
              </Grid>
            </Grid><Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Logged by</TitleLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <EditField
                  isEditable={permissions.isLoggedBy}
                  value={value.loggedBy || ''}
                  onChange={(val) => onChange({
                    ...value,
                    loggedBy: val
                  })} />
              </Grid>
            </Grid><Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <TitleLabel>Sublocation</TitleLabel>
              </Grid>
              <GridAlignContainer item xs={12} md={6}>
                <ColorDot color="gray" />
                <EditLiveSearchField
                  isEditable={permissions.isSubLocation}
                  mainLocationId={value.mainLocationId}
                  subLocationId={value.subLocationId}
                  value={value.subLocation || ''}
                  onChange={async (obj: LocationNodeData) => onChange({
                    ...value,
                    subLocation: obj.name,
                    subLocationId: obj.id
                  })} />
              </GridAlignContainer>
            </Grid>
          </>
          :
          <>
            { permissions.isClientSLA &&
            <Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Client SLA</TitleLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography textColor={value.isClientSLABreached ? COLORS.Red : ''}>{value.clientSLA}</Typography>
              </Grid>
            </Grid>
            }
            { permissions.isOpsSLA &&
            <Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Ops SLA</TitleLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography textColor={value.isOpsSLABreached ? COLORS.Red : ''}>{value.opsSLA}</Typography>
              </Grid>
            </Grid>
            }
            <Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Scheduled</TitleLabel>
              </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <ColorDot color="gray" />
                <EditDateTimePicker
                  isEditable={permissions.isScheduled}
                  value={value.scheduled || ''}
                  disablePast={true}
                  onChange={(val) => onChange({
                    ...value,
                    scheduled: val
                  })} />
              </Grid>
            </Grid>
          </>
        }
      </Grid>

      <Grid item xs md={4} mb={0.2} ml={isMobile ? 2 : 'auto'}>
        {!isMobile && 
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Brand</TitleLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <EditField
              isEditable={permissions.isBrand}
              value={value.brand || ''}
              onChange={(val) => onChange({
                ...value,
                brand: val
              })}
            />
          </Grid>
        </Grid>
        }
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Priority</TitleLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <EditPrioritySelectMenu
              id="service-summary-priority-select"
              isEditable={permissions.isPriority}
              items= {options.priorityAndServicesOptions?.map((obj: any) => ({ 
                value: obj.id.toString(),
                label: obj.value.shortName,
                color: obj.value.color,
                description: obj.value.description,
                disabled: !obj.value.isActive
              })) || []}
              value={value.priorityId?.toString() || ''}
              onChange={(val) => onChange({
                ...value,
                priorityId: val
              })}
              priorityLabel={value.priorityLabel}
              priorityColor={value.priorityColor}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Service Type</TitleLabel>
          </Grid>
          <GridAlignContainer item xs={12} md>
            <ColorDot color={COLORS.DarkCyan} />
            <EditDropdownField
              textColor={COLORS.DarkCyan}
              textFontSize=""
              isEditable={permissions.isServiceType}
              options={serviceTypesData(options.serviceOptions || [])}
              value={value.serviceType || '-1'}
              onChange={(val) => onChange({
                ...value,
                serviceType: val
              })}
              validate={true}
              optionalLabel="Select Service Type"
              optionalLabelEnabled={true}
              errorMessageEnabled={false}
            />
          </GridAlignContainer>
        </Grid>
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Action Type</TitleLabel>
          </Grid>
          <GridAlignContainer item xs={12} md={6}>
            <ColorDot color={COLORS.LightCyan} />
            <EditDropdownField
              textColor={COLORS.LightCyan}
              textFontSize=""
              isEditable={permissions.isActionType}
              options={actionTypesData(options.actionTypeOptions || [])}
              value={value.actionType || ''}
              onChange={(val) => onChange({
                ...value,
                actionType: val
              })}
              validate={true}
              optionalLabel="Select Action Type"
              optionalLabelEnabled={true}
              errorMessageEnabled={false}
            />
          </GridAlignContainer>
        </Grid>
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Process Type</TitleLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <EditDropdownField
              textColor={COLORS.Red}
              isEditable={permissions.isProcessType}
              options={processTypesData(options.processTypeOptions || [])}
              value={value.processType || ''}
              onChange={(val) => onChange({
                ...value,
                processType: val
              })}
              validate={true}
              optionalLabel="Select Process Type"
              optionalLabelEnabled={true}
              errorMessageEnabled={false}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" mb={0.2}>
          <Grid item xs={12} md={6}>
            <TitleLabel>Site Schema</TitleLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            {value.siteSchema || '-'}
          </Grid>
        </Grid>
      </Grid>

      {!isMobile &&
        <Grid item xs md={4}>
          { permissions.isClientSLA &&
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Client SLA</TitleLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography textColor={value.isClientSLABreached ? COLORS.Red : ''}>{value.clientSLA}</Typography>
            </Grid>
          </Grid>
          }
          { permissions.isOpsSLA && 
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Ops SLA</TitleLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography textColor={value.isOpsSLABreached ? COLORS.Red : ''}>{value.opsSLA}</Typography>
            </Grid>
          </Grid>
          }
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Scheduled</TitleLabel>
            </Grid>
            <GridAlignContainer item xs={12} md={6}>
              <ColorDot color="gray" />
              <EditDateTimePicker
                isEditable={permissions.isScheduled}
                value={value.scheduled || ''}
                disablePast={true}
                onChange={(val) => onChange({
                  ...value,
                  scheduled: val
                })}
              />
            </GridAlignContainer>
          </Grid>
          <Grid container alignItems="center" mb={0.2}>
            <Grid item xs={12} md={6}>
              <TitleLabel>Follow Up</TitleLabel>
            </Grid>
            <GridAlignContainer item xs={12} md={6}>
              <ColorDot color="gray" />
              <EditDateTimePicker
                isEditable={permissions.isFollowUp}
                value={value.followUp || ''}
                onChange={(val) => onChange({
                  ...value,
                  followUp: val
                })}
              />
            </GridAlignContainer>
          </Grid>
          {value.presentation &&
            <Grid container alignItems="center" mb={0.2}>
              <Grid item xs={12} md={6}>
                <TitleLabel>Presentation</TitleLabel>
              </Grid>
              <GridAlignContainer item xs={12} md>
                <ColorDot color={COLORS.Gray} />
                <EditDropdownField
                  textColor={COLORS.Black}
                  textFontSize=""
                  isEditable={permissions.isPresentation}
                  options={SERVICE_PRESENTATION_OPTIONS}
                  value={value.presentation || '-1'}
                  onChange={(val) => onChange({
                    ...value,
                    presentation: val
                  })}
                  validate={true}
                  optionalLabel="Select Presentation"
                  optionalLabelEnabled={true}
                  errorMessageEnabled={false}
                />
              </GridAlignContainer>
            </Grid>
          }
        </Grid>
      }
    </Grid>
  );
};