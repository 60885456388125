export enum StructureTypeCategory {
  System = 'system',
  Operational = 'operational',
  Client = 'client'
}

export enum PropertyType {
  Service = 'service',
  Contact = 'contact',
  Parts = 'parts',
  Address = 'address',
  TradingHours = 'trading-hours',
  ServicesAssignment = 'services-assignment',
  SiteSchema = 'site-schema',
  ReferenceNumbers = 'reference-numbers',
  PriorityAndService = 'priority-and-services',
  Coverage = 'coverages',
  Model = 'models',
  FaultCategory = 'fault-category',
  FaultCode = 'fault-code',
  Allocations = 'allocations',
  FleetTag = 'fleet-tag',
  ImportedSession = 'imported-session',
  GlobalReturns = 'global-returns',
  MostRecentPresentation = 'most-recent-presentation'
}

export enum EntityType {
  TYPE_USER = 1,
  TYPE_ROLE = 2,
  TYPE_CLIENTSCONTRACTS = 3,
  TYPE_SERVICES = 4,
  TYPE_PARTTYPES = 5,
  TYPE_SERIALIESEDPARTS = 6,
  TYPE_STRUCTURELOCATIONS = 7,
  TYPE_STRUCTURELOCATIONTYPES = 8,
  TYPE_SAVEDREPORTS = 9,
  TYPE_TLC = 10
}

export enum ServiceAttributeType {
  UserDepotAllocation = 'uda',
  Reference1 = 'ref1',
  Reference2 = 'ref2',
  Reference3 = 'ref3',
  ReportedSerialNumber = 'rsn',
  ReportedModel = 'rm',
  ReportedFaultAndDescription = 'rfd',
  ElementOfDisplaying = 'eod',
  Address = 'address',
  Contact = 'contact'
}

export enum ServiceAttributeName {
  UserDepotAllocation = 'User/Depot Allocation',
  Reference1 = 'Reference 1',
  Reference2 = 'Reference 2',
  Reference3 = 'Reference 3',
  ReportedSerialNumber = 'Reported Serial Number',
  ReportedModel = 'Reported Model',
  ReportedFaultAndDescription = 'Reported Fault and Description',
  ElementOfDisplaying = 'Element of Displaying',
}

export enum NodeAttributeType {
  Address = 'address',
  Contact = 'contact',
  TradingHours = 'trading-hours',
  Services = 'services',
  Parts = 'parts',
  Coverages = 'coverages',
  PriorityAndServices = 'priority-and-services',
  Models = 'models',
  FaultCategory = 'fault-category',
  FaultCode = 'fault-code',
  ReferenceNumbers = 'reference-numbers',
  Capacity = 'capacity',
  FleetTag = 'fleet-tag',
  FreightIntegrationEnabled = 'freight-integration-enabled',
  CourierServiceTypes = 'courier-service-types',
}

export enum ServiceCreationRuleTypes {
  Reference1 = 'ref1',
  Reference2 = 'ref2',
  Reference3 = 'ref3',
  FaultAndDescription = 'faultAndDescription',
  Model = 'model',
  SerialNumber = 'serialNo',
  Notes = 'notes'
}

export enum AddressContactType {
  Main = 'Main',
  Logistics = 'Logistics',
  Alternative = 'Alternative',
  Technical = 'Technical',
  Management = 'Management',
  Notification = 'Notification'
}

export enum SLATargetType {
  OnStatusChange = 'status-change'
}

export enum SerialisedType {
  Serialised = 'Serialised',
  Consumable = 'Consumable',
  Unknown = 'Unknown'
}

export enum ServiceStatusIsClosed {
  Closed = 'Closed',
  Open = 'Open'
}

export enum ContentDispositionType {
  Inline = 'inline',
  Attachment = 'attachment'
}

export enum ImportResourceTypeCode {
  Node = 'node',
  Service = 'service',
  Part = 'part'
}

export enum ImportStatusCode {
  New = 'new',
  PreprocessingInprogress = 'preprocessing-inprogress',
  PreprocessingComplete = 'preprocessing-complete',
  PreprocessingFailed = 'preprocessing-failed',
  ProcessingReady = 'processing-ready',
  ProcessingInprogress = 'processing-inprogress',
  ProcessingComplete = 'processing-complete',
  ProcessingFailed = 'processing-failed',
}

export enum ImportStatusName {
  New = 'New',
  PreprocessingInprogress = 'Pre-processing In Progress',
  PreprocessingComplete = 'Pre-processing Complete',
  PreprocessingFailed = 'Pre-processing Failed',
  ProcessingReady = 'Processing Ready',
  ProcessingInprogress = 'Processing In Progress',
  ProcessingComplete = 'Processing Complete',
  ProcessingFailed = 'Processing Failed',
}

export enum UploadResourceType {
  Structures = 'structures',
  Services = 'services',
}

export enum PartTypeCategory {
  SerialisedDevice = 'serialised-device',
  SerialisedComponent = 'serialised-component',
  NonSerialisedComponent = 'non-serialised-component',
  Consumable = 'consumable',
}

export enum SerializationType {
  Serialised = 'serialised',
  NonSerialised = 'non-serialised'
}

export enum SerializationTypeForList {
  Serialised = 'Serialised',
  NonSerialised = 'Non-Serialised'
}

export enum ImportDataGridInvisibleColumnList {
  Line = 'Line',
  NodeObject = 'nodeObject',
  SubNodeObject = 'subNodeObject',
  ServiceObject = 'serviceObject'
}

export enum ShipmentStatusIsPending {
  Pending = 'Pending',
  Complete = 'Complete'
}

export enum NotificationConfigType {
  Service = 'Service',
  Shipment = 'Shipment'
}

export enum InteractionTemplateFields {
  IsActive = 'active',
  IsMandotory = 'mandatory',
  IsDownloadable = 'downloadReport',
  IsRepeatable = 'repeatable',
  IsEditable = 'editable',
  IsPending = 'pending'
}

export enum NodeTypeCode {
  ShippingCompany = 'shipping-company',
  Sibling = 'sibling'
}

export enum ShipmentTypes {
  AddStock = 'add-stock',
  Dispatch = 'dispatch',
  Return = 'return',
  InternalTransfer = 'internal-transfer',
}

export enum NotificationMessageType {
  SMS = 'sms',
  Email = 'email'
}

export enum CustomShippingPackageType {
  Carton = 'Carton',
  Pallet = 'Pallet',
  Satchel = 'Satchel',
}

export enum FreightPackageTypeName {
  Custom = 'Custom',
}

export enum FreightPackageCriteria {
  Length = 'length',
  Width = 'width',
  Height = 'height',
  Quantity = 'quantity',
  Weight = 'weight',
  Type = 'packageType',
}

export enum IconButtonSize {
  Small = 'small',
  Medium = 'medium',
}

export enum CountryCode {
  Australia = 'AU',
}

export enum FreightIntegrator {
  StarShipIt = 'starshipit',
}

export enum FreightOrderReference {
  OnlineOrder = 'Online Order',
}

export enum IconButtonColor {
  Primary = 'primary',
  Error = 'error'
}

export enum DispatchPrefillParams {
  ServiceId = 'serviceId',
  LocationId = 'locationId',
  LocationName = 'locationName',
  isFromServiceEdit = 'isFromServiceEdit'
}

export enum LogTypeNames {
  Uploads = 'Uploads',
  NewShipmentCreated = 'shipment-created',
  ExistingShipmentLinked = 'shipment-linked',
  InteractionEvent = 'Interaction Events'
}

export enum IconFontSize {
  Large = 'large'
}

export enum SavedReportTypeList {
  ServiceList = 'ServiceList',
  StructureList = 'StructureList',
  PartList = 'PartList',
  UserList = 'UserList',
  ClientContractList = 'ClientContractList',
  ServiceTypeList = 'ServiceTypeList',
  PartTypeList = 'PartTypeList',
  RoleList = 'RoleList',
  AttendanceReportList = 'AttendanceReportList',
  PartMovementList = 'PartMovementList'
}

export enum InteractionRuleTriggerPointType {
  StatusChange = 'status-change',
  SystemEvent = 'system-event'
}

export enum ReportShareTabs {
  DirectShare = 'Share Access With', 
  ScheduleEmail = 'Schedule Email Options'
}

export enum PartLocationTypeCode {
  Node = 'node',
  Part = 'part'
}

export enum SerializedDropDownOtherOption {
  Other = 'other'
}

export enum ScheduleType {
  Daily = 'daily-midnight',
  Monthly = 'monthly-1st-of-month',
  Weekly = 'weekly-midnight-on-sunday',
  Yearly = 'yearly-jan-1st',
  Custom = 'custom'
}

export enum ScheduleTime {
  Daily = '0 0 * * *',
  Monthly = '0 0 1 * *',
  Weekly = '0 0 * * 1',
  Yearly = '0 0 1 1 *',
  Custom = '0 0 1 1 1'
}

export enum WarningMessages {
  ScheduleTimeEmpty = 'Please select the schedule time',
  RecipientsEmpty = 'Recipients emails can not be empty',
  EmailBodyEmpty = 'Please provide a email body',
  SubjectEmpty = 'Please provide a subject',
  AttachmentTypeEmpty = 'Please select the attachment type',
  ScheduleTypeEmpty = 'Please select the schedule type'
}

export enum InteractionRuleSystemEventType {
  PartActionExecution = 'part-action-execution',
  TaskExecution = 'task-execution'
}

export enum VerticlePosition {
  Bottom = 'bottom',
  Top = 'top'
} 

export enum HorizontalPosition {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}

export enum TLCTemplateBehavior {
  Batch = 'batch',
  Immediate = 'immediate'
}

export enum TLCTemplateNotePrompt {
  Batch = 'batch',
  Individual = 'individual'
}

export enum Behaviour {
  Batch = 'batch',
  Immediate = 'immediate'
}

export enum NotePromptBehaviour {
  Batch = 'batch',
  Individual = 'individual'
}

export enum TLCActionTemplateType {
  Serialized_Part_Update = 'serialized-part-update'
}

export enum TLCTemplateActionStatusLabel {
  Validated = 'Validated',
  Executed = 'Transaction completed'
}

export enum TLCTemplateActionErrorLabel {
  Restriction = 'Restriction ',
  TemplateAction = 'Template Action '
}

export enum PartTypeCategoryName {
  SerialisedDevice = 'Serialised Device',
  SerialisedComponent = 'Serialised Component',
  NonSerialisedComponent = 'Non Serialised Component',
  Consumable = 'Consumable',
}

export enum ServiceCancelOrReOpen {
  CancelFutile = 'Cancel/Futile',
  ReOpen = 'Re-Open'
}

export enum ServiceStatusTypes {
  Cancelled = 'cxl',
  Futile = 'futil'
}

export enum CheckInCheckOutType {
  Checkin = 'checkin',
  Checkout = 'checkout'
}

export enum InvoiceType {
  Internal = 'internal',
  External = 'external'
}

export enum ServiceEditFragment {
  Location = 'Location',
  ReportedInfo = 'Reported Info',
  Parts = 'Parts',
  AddNotes = 'Add Notes',
  History = 'History',
  Administration = 'Administration'
}

export enum ServiceStatusNames {
  New = 'New',
  OnHold = 'On Hold',
  OnTheWay = 'On The Way',
  OnSite = 'On Site',
  Complete = 'Complete',
  Futile = 'Futile',
  Cancelled = 'Cancelled',
  Assigned = 'Assigned',
  Scheduled = 'Scheduled',
  Available = 'Available',
  WithLogistics = 'With Logistics',
  Dispatched = 'Dispatched',
  WaitingReturn = 'Waiting Return',
  PartsRequired = 'Parts Required',
  PartsInRepair = 'Parts in Repair',
  PartsInOrder = 'Parts in Order',
  PartsInTransit = 'Parts in Transit',
  PartsAvailable = 'Parts Available',
  Escalated = 'Escalated',
  CallbackRequired = 'Callback Required',
  ReadyForBooking = 'Ready for Booking',
  Exception = 'Exception',
  Testing = 'Testing',
  Repairing = 'Repairing',
  LegacyClosed = 'Legacy Closed'
}

export enum ServiceTypeCode {
  Repair = 'rep',
  Maintenance = 'mtn',
  Support = 'sup',
  Install = 'ins',
  Decom = 'dcm',
  Upgrade = 'upg',
  Audit = 'adt',
  DispatchOrder = 'dsp',
  AdHoc = 'ahc'
}

export enum ServiceAttachmentUploadFeature {
  InteractionFileUpload = 'InteractionFileUpload',
  InteractionPhotoUpload = 'InteractionPhotoUpload',
  ReportedInfo = 'ReportedInfo',
  PartActionPartCreation = 'PartActionPartCreation'
}