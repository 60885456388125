import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Grid, Alert, AlertTitle } from '@mui/material';
import { Done } from '@mui/icons-material';
import { CheckCircleOutline, Cancel } from '@mui/icons-material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionPermissions, ColorProps, CustomPermission, LocationNodeData, NodeType, ParentSelectionType, SiteSchemaDropdown, SizeProps, StructureBasicData, StructureHelperText, StructureLayoutData, StructureTypeResponse, Tag, Warning } from '../../../@types';
import {
  RoleTypography,
  RoleBox,
  ModalBox,
  ModalContent,
  ModalFooter,
  LocationSearchBox
} from './AddEditStructureLocation.styles';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { ToggleButtonGroup, ToogleButtonGroupColor } from '../../../components/atoms/ToogleButtonGroup';
import { StructureLayout } from '../../../components/organisms/StructureLayout';
import { TradingHourList } from '../../../components/organisms/TradingHourList';
import { TradingHoursData } from '../../../components/molecules/TradingHours';
import { STRUCTURE_HELPER_TEXT } from './AddEditStructureLocation.data';
import { StructureChild, ChildrenDataType } from '../../../components/molecules/StructureChild';
import { DropdownItem, SelectMenu } from '../../../components/atoms/SelectMenu';
import { ContactData } from '../../../components/molecules/Contact';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags } from '../../../components/organisms/Tags';
import { useGetStructureTypesByCategoryCode, useGetStructureTypesByCode } from '../../../queries/structure-type-query';
import { useCreateStructure, useGetStructuresByTypeCategoryCodeAndTypeCode, useGetNodeChildrenByNodeType, useGetNodeById, useUpdateStructure, useGetNodeChildren, useUpdateStructureBasicData, useGetNodeSubTree, useGetSiteSchemasByClientContract } from '../../../queries/structure-query';
import { EntityType, PropertyType, PropertyType as PropertyTypeEnum, StructureTypeCategory } from '../../../configs/enums';
import { StyledCloseIcon, StyledPeopleAltOutlinedIcon, StyledSearchIcon } from '../../../components/atoms/ToogleButtonGroup/ToogleButtonGroup.styles';
import { BrandSelector } from '../../../components/molecules/BrandSelector';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { AddressDataType } from '../../../components/organisms/StructureAddress';
import { TextField } from '../../../components/atoms/TextField';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { Box } from '../../../components/atoms/Box';
import { ContactDataType, StructureAddressContactLayout } from '../../../components/templates/StructureAddressContactLayout';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { NotFoundError } from '../../Error/NotFound';
import { isUserHasPermission } from '../../../configs/permissions';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useSearchNodeByParentId } from '../../../queries/live-search-query';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { CapacityData, CapacityHourTypes, DropdownItem as CapacityServiceTypes } from '../../../components/organisms/Capacity/Capacity.props';
import { CapacityList } from '../../../components/templates/CapacityList';
import { useGetAllServiceTypes } from '../../../queries/service-type-query';
import { PartAccordianFragment } from './PartsAccordianFragment';
import { GLOBAL_RETURNS_LOCATION, StructureTypeFields } from '../../../constants/structureType';
import { SearchParams } from './AddEditStructureLocation.props';
import { ADDRESS_CONTACT_TYPES, SAMPLE_CAPACITY_DATA, SAMPLE_STRUCTURE_LAYOUT, SITE_SCHEMA_SAMPLE_DATA, STRUCTURE_ATTRIBUTE_TYPE_FIELDS } from '../../../constants/structure';
import { getSortedStructureTypes, getServiceTypesDropdownData, getNodeAttributes, genarateURL, getWarningsOnlyForStructures, getWarningsForAddressContacts } from '../../../utils/structure';
import { ServiceAccordianFragment } from './ServiceAccordianFragment';
import { getHasCodeForNodeSearch } from '../../../utils/services';
import { ShipmentAccordianFragment } from './ShipmentAccordianFragment';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { LocationMergeAccordianFragment } from './LocationMergeAccordianFragment';
import { PrintLabel } from '../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { Link } from '../../../components/atoms/Link';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { Modal } from '../../../components/atoms/Modal';
import { CloseIcon } from '../../../components/atoms/CloseIcon';
import { getLocalDateString, isDropDownEmpty } from '../../../utils/common';

export const AddEditStructureLocation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get(SearchParams.ParentId);
  const parentName = searchParams.get(SearchParams.ParentName);
  const nodeTypeCode = searchParams.get(SearchParams.NodeTypeCode);
  const preferedNodeTypes = searchParams.getAll(SearchParams.PreferedNodeType);
  const selectedClientId = searchParams.get(SearchParams.ClientId);
  const selectedContractId = searchParams.get(SearchParams.ContractId);
  let isNew = id === 'create';

  const [structureLayoutData, setStructureLayoutData] = useState<StructureLayoutData>(SAMPLE_STRUCTURE_LAYOUT);
  const [structureLayoutSchemaType, setStructureLayoutSchemaType] = useState<Array<SiteSchemaDropdown>>(SITE_SCHEMA_SAMPLE_DATA);
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [addressData, setAddressData] = useState<AddressDataType[]>([]);
  const [tradingHoursData, setTradingHoursData] = useState<TradingHoursData[]>([]);
  const [capacityData, setCapacityData] = useState<CapacityData[]>([]);
  const [structureAddressDataset, setStructureAddressDataset] = useState<AddressDataType[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [editAddressData, setEditAddressData] = useState<AddressDataType>();
  const [selectedSystemTags, setSelectedSystemTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newCreatedTags, setNewCreatedTags] = useState<Tag[]>([]);
  const [structureTypes, setStructureTypes] = useState<Array<StructureTypeResponse>>([]);
  const [selectedStructureType, setSelectedStructureType] = useState(nodeTypeCode || '-1');
  const [propertyTypes, setPropertyTypes] = useState(['']);
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [openLocationSearch, setOpenLocationSearch] = useState(false);
  const [parentType, setParentType] = useState<string>(parentId ? ParentSelectionType.Search : ParentSelectionType.None);
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedParentId, setSelectedParentId] = useState('');
  const [selectedParenHierarchy, setSelectedParenHierarchy] = useState<string | ReactNode>('');
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [serviceTypes, setServiceTypes] = useState<CapacityServiceTypes[]>([]);
  const [capacityClientsDefault, setCapacityClientsDefault] = useState<DropdownItem[]>([]);
  const [capacityContractsDefault, setCapacityContractsDefault] = useState<DropdownItem[]>([]);
  const [brands, setBrands] = useState<DropdownItem[]>([]);
  const [openToast, setOpenToast] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [nodeChildren, setNodeChildren] = useState<ChildrenDataType[]>([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [childNodebasicData, setChildNodebasicData] = useState<StructureBasicData>();
  const [warnings, setWarnings] = useState<Warning[]>();
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [locationName, setLocationName] = React.useState('');
  const [locationId, setLocationId] = React.useState<number>(parseInt(parentId || '0'));
  const [areDependenciesLoaded, setAreDependenciesLoaded] = useState(false);
  const [isSiteSchemaEnabled, setIsSiteSchemaEnabled] = useState(false);
  const [isReferenceNumbersEnabled, setIsReferenceNumbersEnabled] = useState(false);
  const [capacityDefaultValue, setCapacityDefaultValue] = React.useState<CapacityData>(SAMPLE_CAPACITY_DATA);
  const [clientId, setClientId] = useState('');
  const [contractId, setContractId] = useState('');
  const [checkedGlobalReturns, setCheckedGlobalReturns] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [contactForm, setContactForm] = useState<any[]>([]);
  const [addressForm, setAddressForm] = useState<any[]>([]);
  const [parentNodeName, setParentNodeName] = useState<string | ReactNode>('');
  const [helperText, setHelperText] = useState<StructureHelperText>(STRUCTURE_HELPER_TEXT);
  const [validate, setValidate] = useState(false);
  const [parentSelect, setParentSelect] = useState(false);

  const { data: structureTypesData } = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);
  const createStructure = useCreateStructure();
  const updateStructure = useUpdateStructure();
  const getNodeData = useGetNodeById(id || '', StructureTypeCategory.Operational);
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getStructuresByTypeCategoryCodeAndContractTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Contract);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const getNodeChildren = useGetNodeChildren(id || '');
  const updateStructureBasicData = useUpdateStructureBasicData();
  const getNodeSubTree = useGetNodeSubTree(id || '');
  const getStructureTags = getAllTags(EntityType.TYPE_STRUCTURELOCATIONS);
  const addTagsQuery = useAddTags();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getNodeSubTreeQuery = useGetNodeSubTree(locationId?.toString() || '0');
  const searchNodeByParentId = useSearchNodeByParentId();
  const getServiceTypes = useGetAllServiceTypes();
  const getStructureTypesByCode = useGetStructureTypesByCode(selectedStructureType);
  const getSiteSchemasByClientContract = useGetSiteSchemasByClientContract(clientId, contractId);

  TabHandler(isNew ? `Create Structure | ${PLATFORM_NAME}` : `Structure: ${id} | Structures | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    getServiceTypes.data && setServiceTypes(getServiceTypesDropdownData(getServiceTypes.data));
  }, [getServiceTypes.data]);

  useEffect(() => {
    if (capacityData) {
      getStructuresByTypeCategoryCodeAndTypeCode.refetch();
      getStructuresByTypeCategoryCodeAndContractTypeCode.refetch();
    }
  }, [capacityData]);

  useEffect(() => {
    if (serviceTypes.length > 0 && capacityClientsDefault.length > 0 && capacityContractsDefault.length > 0) {
      setCapacityDefaultValue({
        type: CapacityHourTypes.AllDays,
        checkInventory: true,
        allowOveride: true,
        max: '4',
        itemsData: serviceTypes,
        clientsData: capacityClientsDefault,
        contractsData: capacityContractsDefault,
      });
      setAreDependenciesLoaded(true);
    }

  }, [serviceTypes, capacityClientsDefault, capacityContractsDefault]);

  useEffect(() => {
    if (locationId) {
      getNodeSubTreeQuery.refetch();
    }
  }, [locationId]);

  useEffect(() => {
    if (getNodeSubTreeQuery.data) {
      const clientNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Client);
      const parentNode = getNodeSubTreeQuery.data.find(item => item.id === locationId);
      
      setParentNodeName(parentNode ? <Link href={`/structure/${parentNode.id}`}>{parentNode.name}</Link> : '');

      if (clientNode) {
        setClients([{ value: clientNode.id.toString(), label: clientNode.name, disabled: !clientNode.isActive }]);
      }

      const contractNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Contract);

      if (contractNode) {
        setContracts([{ value: contractNode.id.toString(), label: contractNode.name, disabled: !contractNode.isActive }]);
      }

      const brandNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Brand);
      setSelectedParenHierarchy(clientNode ? ( 
        <>
          <Link href={`/configure/clients/${clientNode?.nodeTypeCode}/${clientNode?.id}`}>{clientNode?.name}</Link>/
          <Link href={`/configure/clients/${contractNode?.nodeTypeCode}/${contractNode?.id}`}>{contractNode?.name}</Link>/
          {brandNode?.name}
        </> 
      ) : '-');
      setSelectedBrandId(locationId?.toString() || '');
    }
  }, [getNodeSubTreeQuery.data]);

  useEffect(() => {
    if (!isNew) {
      setStructureContactDataset([]);
      setStructureAddressDataset([]);
      setTradingHoursData([]);
      setCapacityData([]);
      setStructureLayoutData(SAMPLE_STRUCTURE_LAYOUT);
      setCheckedGlobalReturns(false);
      setSelectedBrandId('');
      setSelectedParenHierarchy('');
      setLocationId(NaN);
      setLocationName('');

      getNodeData.refetch();
      getNodeChildren.refetch();
    }
    //This is for a scenario where user navigates from node edit page to create page
    //To reinitialize state 
    else if (isNew && structureLayoutData.id) {
      window.location.reload();
    }
  }, [isNew, id]);

  useEffect(() => {
    getStructureTags.data && setAllTags(getStructureTags.data);
  }, [getStructureTags.data]);

  useEffect(() => {
    if (getNodeData.data && areDependenciesLoaded) {
      const data = getNodeData.data;
      setSelectedStructureType(data.nodeTypeCode);
      setSelectedSystemTags(mapSavedTags(data.tags));

      const relevantWarnings = getWarningsOnlyForStructures(data.warnings);
      setWarnings(relevantWarnings);
      setShowWarningToast(relevantWarnings.length > 0);
      setIsActive(data.isActive);

      if (data.parentNodeId) {
        getNodeSubTree.refetch();
      }

      setStructureLayoutData({
        id: data.id,
        name: data.name,
        notes: data.notes,
        selectedTags: [],
        newTags: [],
        referenceNo1: '',
        referenceNo2: '',
        referenceNo3: '',
        siteSchemaId: '0'
      });

      if (propertyTypes.length > 1) {
        setStructureContactDataset([]);
        setStructureAddressDataset([]);
        setTradingHoursData([]);
        setCapacityData([]);
        setCheckedGlobalReturns(false);

        data.attributes?.forEach((attribute: any) => {
          const { id, propertyTypeCode, value, updatedAt } = attribute;

          if (propertyTypeCode === PropertyTypeEnum.Contact && value?.isActive) {
            setStructureContactDataset(prev => [...prev, { ...value, id: id }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.Address && value?.isActive) {
            setStructureAddressDataset(prev => [...prev, {
              ...value,
              id: id,
              name: value.addressName
            }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.TradingHours) {
            setTradingHoursData(prev => [...prev, { ...value, id: id }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.Allocations) {
            setCapacityData(prev => [...prev, { ...value, id, itemsData: serviceTypes, clientsData: capacityClientsDefault, contractsData: capacityContractsDefault }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.ReferenceNumbers) {
            setStructureLayoutData(prev => ({ ...prev, refAttribId: id, referenceNo1: value.referenceNo1, referenceNo2: value.referenceNo2, referenceNo3: value.referenceNo3 }));
          }
          if (propertyTypeCode === PropertyTypeEnum.SiteSchema) {
            setStructureLayoutData(prev => ({ ...prev, siteSchemaAttribId: id, siteSchemaId: value.siteSchemaId }));
          }
          if (propertyTypeCode === PropertyTypeEnum.MostRecentPresentation) {
            setStructureLayoutData(prev => ({ ...prev, mostRecentPresentation: { description: value?.description, date: getLocalDateString(updatedAt) } }));
          }
          if (
            propertyTypeCode === PropertyTypeEnum.GlobalReturns &&
            isUserHasPermission(
              ActionPermissions.Configure_Structure_Type_Edit,
              permissions,
            ) &&
            propertyTypes.includes(PropertyType.GlobalReturns)
          ) {
            setCheckedGlobalReturns(value.globalReturns);
          }
        });
      }
    }

  }, [getNodeData.data, areDependenciesLoaded, propertyTypes]);

  useEffect(() => {
    selectedStructureType && getStructureTypesByCode.refetch();
  }, [selectedStructureType]);

  useEffect(() => {
    selectedClientId && setClientId(selectedClientId);
    selectedContractId && setContractId(selectedContractId);
  }, [selectedClientId, selectedContractId]);

  useEffect(() => {
    if (clientId && contractId) {
      getSiteSchemasByClientContract.refetch();
    }
  }, [clientId, contractId]);

  useEffect(() => {
    getSiteSchemasByClientContract.data && setStructureLayoutSchemaType(getSiteSchemasByClientContract.data);
  }, [getSiteSchemasByClientContract.data]);

  // Use for Site schema field enable / disable logic.
  useEffect(() => {
    if (getStructureTypesByCode.data) {
      // Check selected structure type has site schema propertyType
      const propertyTypeCheck = getStructureTypesByCode.data.propertyTypes?.some(item => item.propertyType === PropertyTypeEnum.SiteSchema);
      const referenceNumbersEnabled = getStructureTypesByCode.data.propertyTypes?.some(item => item.propertyType === PropertyTypeEnum.ReferenceNumbers);

      setIsSiteSchemaEnabled(propertyTypeCheck);
      (propertyTypeCheck && getSiteSchemasByClientContract.data) && setStructureLayoutSchemaType(getSiteSchemasByClientContract.data);

      setIsReferenceNumbersEnabled(referenceNumbersEnabled);
    }
  }, [getStructureTypesByCode.data, getSiteSchemasByClientContract.data]);

  const handleChange = (event: any, type: string) => {
    if (!parentId) {
      type && setParentType(type);
      if (type === NodeType.Client) {
        if (isNew || !isNew && selectedBrandId === null) {
          getStructuresByTypeCategoryCodeAndTypeCode.refetch();
        }
      }
    }
  };

  useEffect(() => {
    if (getNodeSubTree.data) {
      const parentNode = getNodeSubTree.data.find(node => node.id === Number(getNodeData?.data?.parentNodeId));
      if (parentNode?.nodeTypeCode !== NodeType.Brand) {
        getNodeData.data?.clientId && setClientId(getNodeData.data.clientId.toString());
        getNodeData.data?.contractId && setContractId(getNodeData.data.contractId.toString());
        parentNode && setSelectedBrandId(parentNode.id.toString());
        setSelectedParenHierarchy(<><Link href={`/structure/${parentNode?.id}`}>{parentNode?.name}</Link></> || '');
      }
      else {
        setSelectedBrandId(getNodeData?.data?.parentNodeId || '');
        const clientNode = getNodeSubTree.data.find(item => item.nodeTypeCode === NodeType.Client);

        if (clientNode) {
          setClientId(clientNode.id.toString());
          setClients([{ value: clientNode.id.toString(), label: clientNode.name, disabled: !clientNode.isActive }]);
        }

        const contractNode = getNodeSubTree.data.find(item => item.nodeTypeCode === NodeType.Contract);

        if (contractNode) {
          setContractId(contractNode.id.toString());
          setContracts([{ value: contractNode.id.toString(), label: contractNode.name, disabled: !contractNode.isActive }]);
        }

        const brandNode = getNodeSubTree.data.find(item => item.nodeTypeCode === NodeType.Brand);
        setSelectedParenHierarchy(
          <>
            <Link href={`/configure/clients/${clientNode?.nodeTypeCode}/${clientNode?.id}`}>{clientNode?.name}</Link>/
            <Link href={`/configure/clients/${contractNode?.nodeTypeCode}/${contractNode?.id}`}>{contractNode?.name}</Link>/
            {brandNode?.name}
          </>
        );
        setParentType(ParentSelectionType.Client);
      }
    }

  }, [getNodeSubTree.data, getNodeData.data]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      setClients(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive })));
      setCapacityClientsDefault([
        { value: 'any', label: 'Any' },
        ...getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name }))
      ]);
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data, getStructuresByTypeCategoryCodeAndTypeCode.isRefetching]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndContractTypeCode.data) {
      setCapacityContractsDefault(getStructuresByTypeCategoryCodeAndContractTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name })));
      setCapacityContractsDefault([
        { value: 'any', label: 'Any' },
        ...getStructuresByTypeCategoryCodeAndContractTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name }))
      ]);
    }
  }, [getStructuresByTypeCategoryCodeAndContractTypeCode.data]);

  useEffect(() => {
    if (getNodeChildren.data) {
      setNodeChildren(getNodeChildren.data.map(item => ({ id: item.id, name: item.name, type: item.nodeTypeCode, isActive: item.isActive, typeName: item.nodeType.name })));
    }
  }, [getNodeChildren.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      if (isNew && queryNodeType !== NodeType.Client || !isNew && !selectedBrandId || !isNew && queryNodeType === NodeType.Brand) {
        getNodeChildrenByNodeType.refetch();
      }
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        setContracts(contractMenuList);
      }
      else if (queryNodeType === NodeType.Brand) {
        const brandMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        setBrands(brandMenuList);
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    structureTypesData && setStructureTypes(getSortedStructureTypes(structureTypesData, preferedNodeTypes));
  }, [structureTypesData]);

  useEffect(() => {
    if (selectedStructureType) {
      const selectedPropertyTypes = structureTypes.find(item => item.code === selectedStructureType)?.propertyTypes;

      if (selectedPropertyTypes) {
        setPropertyTypes(selectedPropertyTypes.map(property => property.propertyType));
      }
      else {
        setPropertyTypes([]);
      }
    }
  }, [structureTypes, selectedStructureType]);

  useEffect(() => {
    structureLayoutData.selectedTags = [...selectedSystemTags];
    structureLayoutData.newTags = [...newCreatedTags];
  }, [selectedSystemTags, newCreatedTags]);

  const onChangeGlobalReturns = (event: ChangeEvent<HTMLInputElement>) => setCheckedGlobalReturns(event.target.checked);

  const isValid = () => {
    setHelperText({ 
      ...helperText, 
      nameHelperText: !structureLayoutData.name ? 'Name is required' : '',
    });

    return !isDropDownEmpty(selectedStructureType.toString()) && !!structureLayoutData.name;
  };

  const onHandleSave = async () => {
    const warningsForAddressContacts = getWarningsForAddressContacts(addressForm, contactForm);
    setWarnings(warningsForAddressContacts);
    setShowWarningToast(warningsForAddressContacts.length > 0);

    if (warningsForAddressContacts.length > 0) {
      return;
    }

    if (!isValid()) {
      setValidate(true);
      return;
    }

    setDisableSave(true);
    const attributes = getNodeAttributes(
      structureAddressDataset,
      propertyTypes,
      structureContactDataset,
      tradingHoursData,
      capacityData,
      structureLayoutData,
      checkedGlobalReturns,
      isSiteSchemaEnabled
    );

    if (isNew) {
      const newNode: any = await createStructure.mutateAsync({
        ...structureLayoutData,
        code: structureLayoutData.name,
        isActive: true,
        parentNodeId: selectedBrandId ? selectedBrandId : parentId || null,
        nodeTypeCode: selectedStructureType,
        attributes: attributes
      }).catch((error) => error && setDisableSave(false));

      if (newNode?.isAllowedAtRoot === false) {
        setShowWarningToast(true);
        setWarnings([{
          field: 'Note Type',
          message: 'Parent is not provided, Check if Node Type allowed to be at Root'
        }]);
        setDisableSave(false);
        return;
      } else {
        setWarnings([]);
        await saveTags(newNode.id);

        setOpenToast(true);

        // empty states to avoid duplicate displaying of records
        setStructureAddressDataset([]);
        setStructureContactDataset([]);
        setSelectedBrandId('');

        setTimeout(() => {
          const url = `/structure/${newNode.id}`;
          navigate(url, { replace: true });
          setDisableSave(false);
        }, 2000);
      }
    }
    else {
      await updateStructure.mutateAsync({
        ...structureLayoutData,
        id: id,
        code: structureLayoutData.name,
        isActive: isActive,
        parentNodeId: selectedBrandId ? selectedBrandId : locationId || null,
        attributes: attributes
      });

      if (id) {
        await saveTags(id);
      }
      setDisableSave(false);
      setOpenToast(true);
    }
    await getNodeData.refetch();
    setParentSelect(false);
  };

  const saveTags = async (nodeId: string) => {
    const existingTags = getSelectedExistingTagsMapped(selectedSystemTags);
    const freshTags = getNewTagsMapped(newCreatedTags);

    await addTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_STRUCTURELOCATIONS,
      entityId: nodeId,
      freshTags,
      existingTags
    });
  };

  const handleParentSelection = (selectedBrandId: string, value: string) => {
    setSelectedBrandId(selectedBrandId);
    setSelectedParenHierarchy(value);
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    if (nodeType === NodeType.Contract) {
      setClientId(parentId);
    } else {
      setContractId(parentId);
    }

    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const onAddNewChildStructure = (selectedNodeTypeCode?: string, nodeId?: string) => {
    if (nodeId) {
      const selectedNode = nodeChildren.find(item => item.id === +nodeId);

      if (selectedNode) {
        setChildNodebasicData({ id: selectedNode.id, name: selectedNode.name, isActive: selectedNode.isActive });
        setShowEditPopup(true);
      }
    }
    else {
      let clientNodeId = clientId?.toString();

      if (!clientNodeId) {
        clientNodeId = getNodeData.data?.clientId?.toString() || '';
      }

      const url = genarateURL(id, structureLayoutData.name, selectedNodeTypeCode, selectedStructureType, structureTypes, clientNodeId, contractId);
      window.open(url, '_self');
    }
  };

  const onChangeChildNodeData = async () => {
    await updateStructureBasicData.mutateAsync(childNodebasicData);
    setNodeChildren(prev => {
      const node = prev.find(item => item.id === childNodebasicData?.id);

      if (node) {
        node.name = childNodebasicData?.name || node.name;
        node.isActive = childNodebasicData?.isActive || node.isActive;
      }

      return prev;
    });
    setShowEditPopup(false);
    setOpenToast(true);
  };

  const onChangeActiveFlag = async (isActive: boolean, id: string) => {
    const selectedNode = nodeChildren.find(item => item.id === +id);

    if (selectedNode) {
      await updateStructureBasicData.mutateAsync({
        ...selectedNode,
        isActive: isActive
      });

      setNodeChildren(prev => {
        const node = prev.find(item => item.id === +id);

        if (node) {
          node.isActive = isActive;
        }

        return prev;
      });
    }
  };

  if (id === 'create' && isUserHasPermission(ActionPermissions.Structure_Create_General, permissions)) {
    isNew = true;
  } else if (getNodeData.data && isUserHasPermission(ActionPermissions.Structure_Edit_View_Structure_Location, permissions)) {
    isNew = false;
  } 
  
  if (id !== 'create' && !getNodeData.data) {
    return getNodeData.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <RoleTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        {`${isNew ? 'Create' : 'Update'} Structure Location`}
      </RoleTypography>
      <RoleBox>
        <Grid container spacing={GRID_SPACING}>
          {(isNew || !getNodeData?.data?.parentNodeId) &&
            <Grid item xs={12}>
              <Typography variant={TypographyVariantProps.H6}>
                Parent
              </Typography>
              <Grid xs={12} mb={2}>
                <ToggleButtonGroup
                  size={SizeProps.Small}
                  color={ToogleButtonGroupColor.Standard}
                  value={parentType}
                  onChange={handleChange}
                  exclusive={!parentId}
                  items={[
                    {
                      value: ParentSelectionType.None,
                      label: 'None',
                      icon: <StyledCloseIcon />,
                      onClick: value => {
                        if (value) {
                          setLocationId(NaN);
                          setLocationName('');
                          setSelectedBrandId('');
                          setSelectedParenHierarchy('');
                          setClients([]);
                          getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                        }
                      }
                    },
                    {
                      value: ParentSelectionType.Client,
                      label: 'Client',
                      subLabel: parentType === ParentSelectionType.None ? '' : parentId ? '' : selectedParenHierarchy,
                      icon: <StyledPeopleAltOutlinedIcon />,
                      onClick: value => {
                        if (value === NodeType.Client && !parentName) {
                          setOpenParentSelector(true);
                          setLocationId(NaN);
                          setLocationName('');
                          setClients([]);
                          setSelectedParenHierarchy('');
                          getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                        }
                      }
                    },
                    {
                      value: ParentSelectionType.Search,
                      label: 'Search',
                      subLabel: parentType === ParentSelectionType.None ? '' : locationName ? locationName : parentNodeName,
                      icon: <StyledSearchIcon />,
                      onClick: value => {
                        if (value && !parentName) {
                          setOpenLocationSearch(true);
                        }
                      }
                    }
                  ]}
                />
              </Grid>
              <Grid xs={4} mb={1} display={isNew ? '' : 'none'}>
                <SelectMenu
                  id="type-selection"
                  label="Structure Type"
                  selectedValue={selectedStructureType}
                  required={true}
                  items={structureTypes.map(item => {
                    return { value: item.code, label: item.name };
                  })}
                  disabled={!isNew}
                  validate={validate}
                  optionalLabelEnabled={true}
                  onChange={(structureType) => setSelectedStructureType(structureType)} />
              </Grid>
            </Grid>
          }
          <Grid container justifyContent="space-between" mx={3}>
            <Grid xs={4}>
              {!isNew && (selectedBrandId !== null && selectedBrandId !== '' || getNodeData?.data?.parentNodeId != null) && selectedParenHierarchy !== '-' && !parentSelect &&
                <>
                  <Typography variant={TypographyVariantProps.H6}>
                    Parent
                  </Typography>
                  <Grid xs={10} mb={2}>
                    <ToggleButtonGroup
                      size={SizeProps.Small}
                      color={ToogleButtonGroupColor.Standard}
                      value={parentType}
                      exclusive={true}
                      items={[
                        {
                          value: 'client',
                          label: parentType === ParentSelectionType.Client && selectedBrandId !== null && selectedBrandId !== '' ? 'Client' : 'Structure',
                          subLabel:  locationId && locationName ? <><Link href={`/structure/${locationId}`}>{locationName}</Link></> : selectedParenHierarchy,
                          icon: <StyledPeopleAltOutlinedIcon />
                        }
                      ]}
                    />
                  </Grid>
                </>
              }
              {!isNew &&
                <Grid xs={12} mb={1}>
                  <SelectMenu
                    id="type-selection"
                    label="Structure Type"
                    selectedValue={selectedStructureType}
                    items={structureTypes.map(item => {
                      return { value: item.code, label: item.name };
                    })}
                    disabled={true}
                    onChange={(value) => { setSelectedStructureType(value); }}
                  />
                </Grid>
              }
              <StructureLayout
                helperText={helperText}
                setHelperText={setHelperText}
                structureLayoutData={structureLayoutData}
                structureLayoutSchemaType={structureLayoutSchemaType}
                onChange={(structureData: StructureLayoutData) => {
                  setStructureLayoutData(structureData);
                  if(structureData.name){
                    selectedStructureType && setDisableSave(false);
                  }
                }}
                allTags={allTags}
                renderNotes={true}
                enableSiteSchemaType={isSiteSchemaEnabled}
                enableReferenceNumbers={isReferenceNumbersEnabled}
                onChangeSelectedTags={(value) => {
                  setSelectedSystemTags([...value]);
                }}
                onChangeNewTags={(value) => {
                  setNewCreatedTags([...value]);
                }}
                assignedSelectedTags={[...selectedSystemTags]}
                assignedNewTags={[...newCreatedTags]}
              />
            </Grid>
            <Grid xs={8}>
              {!isNew &&
                <>
                  <Grid container ml={2} mb={2} mt={2}>
                    <Grid mr={1}>
                      <FormControlLabel
                        control={<Switch onChange={handleIsActiveChange} defaultChecked checked={isActive} color={ColorProps.Primary} />}
                        label="Active"
                      />
                    </Grid>
                  </Grid>
                  {StructureTypeFields.filter(type => !STRUCTURE_ATTRIBUTE_TYPE_FIELDS.includes(type.key)).map((item, index) => (
                    <Grid key={index} container ml={2} mb={1}>
                      <Grid mr={1}>
                        {propertyTypes.includes(item.key) &&
                          <CheckCircleOutline fontSize="small" color="primary" />
                        }
                        {!propertyTypes.includes(item.key) &&
                          <Cancel fontSize="small" color="warning" />
                        }
                      </Grid>
                      <Grid xs={4}>
                        {item.value}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid direction="column" container ml={2} mb={1}>
                    {propertyTypes.includes(PropertyType.GlobalReturns) && isUserHasPermission(ActionPermissions.Configure_Structure_Type_Edit, permissions)
                      &&
                      <Grid mr={1}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkedGlobalReturns} onChange={onChangeGlobalReturns} color={CheckboxColorProps.Primary} />
                          }
                          label={GLOBAL_RETURNS_LOCATION}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid container ml={2} mb={1} mt={2}>
                    <Grid item>
                      <PrintLabel entityId={Number(id) || 0} entityType={PrintLabelEntityTypes.Structure}/>
                    </Grid>
                  </Grid>
                </>
              }
              {propertyTypes.includes(PropertyTypeEnum.Address) &&
                <StructureAddressContactLayout
                  heading="Address"
                  isAddress={true}
                  renderNotes={false}
                  contactType={ADDRESS_CONTACT_TYPES}
                  addressDataset={structureAddressDataset ? structureAddressDataset : []}
                  contactDataset={structureContactDataset}
                  contactFormData={contactData}
                  addressFormData={addressData}
                  editAddressFormData={editAddressData}
                  onClickAddressEdit={(event, index) => {
                    const selectedValue: any = structureAddressDataset.splice(index, 1);
                    setStructureAddressDataset(structureAddressDataset);
                    setEditAddressData(selectedValue[0]);
                  }}
                  onClickAddressDelete={(event, index) => {
                    const updatedData = [...structureAddressDataset];
                    if (index > -1) updatedData[index].isActive = false;
                    setStructureAddressDataset(updatedData);
                  }}
                  onStructureAddressChange={(value) => {
                    setAddressData(value);
                  }}
                  handleAddressCreate={(index) => {
                    setStructureAddressDataset([...structureAddressDataset, addressData[index]]);
                    addressData.splice(index, 1);
                    setAddressData([...addressData]);
                  }}
                  addressForm={addressForm}
                  setAddressForm={setAddressForm}
                />
              }
              {propertyTypes.includes(PropertyTypeEnum.Contact) &&
                <StructureAddressContactLayout
                  heading="Contact"
                  isAddress={false}
                  renderNotes={false}
                  contactType={ADDRESS_CONTACT_TYPES}
                  addressDataset={structureAddressDataset}
                  contactDataset={structureContactDataset ? structureContactDataset : []}
                  contactFormData={contactData}
                  addressFormData={addressData}
                  editContactFormData={editContactData}
                  onClickContactEdit={(event, index) => {
                    const selectedValue: any = structureContactDataset.splice(index, 1);
                    setStructureContactDataset(structureContactDataset);
                    setEditContactData(selectedValue[0]);
                  }}
                  onClickContactDelete={(event, index) => {
                    const updatedData = [...structureContactDataset];
                    if (index > -1) updatedData[index].isActive = false;
                    setStructureContactDataset(updatedData);
                  }}
                  onStructureAddressChange={() => ''}
                  onContactChange={(value) => {
                    setContactData(value);
                  }}
                  handleContactCreate={(index) => {
                    setStructureContactDataset([...structureContactDataset, contactData[index]]);
                    contactData.splice(index, 1);
                    setContactData([...contactData]);
                  }}
                  contactForm={contactForm}
                  setContactForm={setContactForm}
                />
              }
              {propertyTypes.includes(PropertyTypeEnum.TradingHours) &&
                <TradingHourList
                  heading="Trading Hours"
                  tradingHourValue={tradingHoursData}
                  onTradingHoursChange={(value) => setTradingHoursData(value)}
                  onTradingHoursCreate={(value) => setTradingHoursData(value)}
                  onTradingHoursDelete={(index) => {
                    const updatedData = [...tradingHoursData];
                    if (index > -1) {
                      updatedData.splice(index, 1);
                    }
                    setTradingHoursData([...updatedData]);
                  }}
                />
              }
              {propertyTypes.includes(PropertyTypeEnum.Allocations) &&
                <CapacityList
                  heading="Capacity"
                  capacityValue={capacityData}
                  capacityDefaultValue={capacityDefaultValue}
                  onCapacityChange={value => setCapacityData(value)}
                  onCapacityCreate={(value) => setCapacityData(value)}
                  onCapacityDelete={(index) => {
                    const updatedData = [...capacityData];
                    if (index > -1) {
                      updatedData.splice(index, 1);
                    }
                    setCapacityData([...updatedData]);
                  }}
                />
              }
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2} mr={3} mb={4}>
              <Grid item>
                <Button disabled={disableSave} variant={ButtonVariantProps.Primary} startIcon={<Done />} onClick={onHandleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
            {!isNew &&
              <Grid container spacing={GRID_SPACING}>
                <Grid item xs={12}>
                  <StructureChild
                    editLabel="Edit"
                    switchLabel="Active"
                    childrenData={nodeChildren}
                    onClick={onAddNewChildStructure}
                    onChangeActiveFlag={onChangeActiveFlag}
                  />
                </Grid>
              </Grid>
            } {!isNew && id && propertyTypes.includes(PropertyType.Parts) &&
              <Grid container marginTop={2}>
                <Grid item xs={12}>
                  <PartAccordianFragment
                    nodeId={parseInt(id)}
                  />
                </Grid>
              </Grid>
            } {!isNew && id && (propertyTypes.includes(PropertyType.Service) || propertyTypes.includes(PropertyType.ServicesAssignment)) &&
              <Grid container marginTop={2}>
                <Grid item xs={12}>
                  <ServiceAccordianFragment nodeId={parseInt(id)} permissions={permissions} />
                </Grid>
              </Grid>
            } {!isNew && id &&
              <Grid container marginTop={2}>
                <Grid item xs={12}>
                  <ShipmentAccordianFragment
                    nodeId={parseInt(id)}
                  />
                </Grid>
              </Grid>
            }
            {!isNew && id && isUserHasPermission(ActionPermissions.Structure_Edit_Allow_Merge, permissions) &&
              <Grid container marginTop={2}>
                <Grid item xs={12}>
                  <LocationMergeAccordianFragment mergeNodeId={parseInt(id)} nodeTypeCode={selectedStructureType} />
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </RoleBox>
      <Modal
        open={showEditPopup}
        onClose={() => setShowEditPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          <CloseIcon handleClose={() => setShowEditPopup(false)} />
          <ModalContent>
            <TextField label="Name" value={childNodebasicData?.name} onChange={(value: string) => {
              setChildNodebasicData(prev => {
                if (prev) {
                  return { ...prev, name: value };
                }
              });
            }}></TextField>
          </ModalContent>
          <ModalFooter>
            <Button variant={ButtonVariantProps.Primary} startIcon={<Done />} onClick={onChangeChildNodeData}>
              Save
            </Button>
          </ModalFooter>
        </ModalBox>
      </Modal>
      <BrandSelector
        open={openParentSelector}
        onChangeNodeType={onChangeNodeType}
        handleClose={() => { setOpenParentSelector(false); }}
        handleParentSelection={handleParentSelection}
        isActiveClear={false}
        clients={clients}
        contracts={contracts}
        brands={brands}
      />
      <Modal
        open={openLocationSearch}
        onClose={() => { setOpenLocationSearch(false); }}
      >
        <LocationSearchBox>
          <LiveSearchBox
            title="Location Search"
            timeOffset={400}
            value={locationName || ''}
            onClearValue={() => setSelectedBrandId('')}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: LocationNodeData) => {
              setLocationName(obj?.nodeName || obj.name);
              setLocationId(obj.id);
              setOpenLocationSearch(false);
              setParentSelect(true);
            }}
            onApiInvoke={async (name) => {
              const hasCode = getHasCodeForNodeSearch(name);
              const response = await searchNodeByParentId.mutateAsync({ selectedParentId: selectedBrandId, name, hasCode: hasCode });
              const responseWithoutCurrentNode = response.filter(result => isNew || id && result.id !== Number(id));
              return responseWithoutCurrentNode;
            }}
          />
        </LocationSearchBox>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => { setOpenToast(false); }}
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={8000} onClose={() => { setShowWarningToast(false); }}>
        <Alert onClose={() => { setShowWarningToast(false); }} severity="warning">
          <AlertTitle>Warnings</AlertTitle>
          {warnings && warnings.map((warning, index) => <Box key={index}>{warning.message}</Box>)}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};