import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridProProps, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Delete } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { Typography } from '../../atoms/Typography';
import { ReportedInformationProps } from './ReportedInformation.props';
import { BoldLabel, ItalicLabel, Label, SmallItalicLabel, StyledAccordionSummary, GridContainer, SmallLabel, StyledLink, SmallShipmentLabel, StyledIconButton } from './ReportedInformation.styles';
import { formatDate } from '../../../utils/common';
import { DataGrid } from '../../atoms/DataGrid';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { ServiceData } from '../../../@types/service-search.type';
import { useSearchLinkShipments, useSearchService } from '../../../queries/live-search-query';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { IdSearchItem } from '../../molecules/IdSearchItem';
import { useUpdateParentService } from '../../../queries/service-query';
import { EditField } from '../../molecules/EditField';
import { EditDropdownField } from '../../molecules/EditDropdownField';
import { reportedFaultTypes } from '../../../utils/services';
import { Attachments } from '../Attachments';
import { Link, UnderlineTypeProps } from '../../atoms/Link';
import { EditNodePartLiveSearch } from '../EditNodePartLiveSearch';
import { ActionPermissions, CustomPermission, LinkShipmentSearchResult, LinkedChildServiceData, LinkedParentServiceData, ScreenSize } from '../../../@types';
import { useCreateShipmentServiceAllocation, useDeleteShipmentServiceAllocation, useGetShipmentServiceAllocationList } from '../../../queries/shipment-query';
import { ShipmentServiceAllocationList } from '../../../@types/shipment.type';
import { LinkShipmentSearchItem } from '../../molecules/LinkShipmentSearchItem';
import { LogTypeNames } from '../../../configs/enums';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { MobileDataGridItem } from '../MobileDataGridItem';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { genarateNewLinkShipmentURL } from '../../../utils/shipment';
import { SERVICE_ATTACHMENTS_MIME_TYPES } from '../../../configs/envs';
import { ServiceAttachmentMimeTypes } from '../../../configs/uploaded-files';
import { getMatchingMimeTypes } from '../../../utils/upload-file';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { COLORS } from '../../../configs/colors';

export const ReportedInformation: React.FC<ReportedInformationProps> = ({
  value,
  permissions,
  options,
  linkedServiceValue,
  onChange,
  attachmentRows,
  onClick,
  setFileDetails,
  onDispatch,
  expandOnMobile
}) => {
  const [isExpanded, setIsExpanded] = useState(expandOnMobile);
  const [linkedChildServices, setLinkedChildServices] = useState<LinkedChildServiceData[]>([]);
  const [linkedParentService, setLinkedParentService] = useState<LinkedParentServiceData>();
  const [selectedParentServiceId, setSelectedParentServiceId] = useState<number>(NaN);
  const focusRef = useRef<any>({});
  const [locationSearchError] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isChild, setIsChild] = useState(false);
  const [isShowShipment, setIsShowShipment] = useState(false);
  const [linkShipmentId, setLinkShipmentId] = useState<string>('');
  const [shipmentsRows, setShipmentsRows] = useState<ShipmentServiceAllocationList[]>([]);
  const [permissionList, setPermissions] = useState<Array<CustomPermission>>();
  
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const searchServiceQuery = useSearchService();
  const updateParentServiceQuery = useUpdateParentService(value.id);
  const searchLinkShipmentsQuery = useSearchLinkShipments();
  const getShipmentServiceAllocationListQuery = useGetShipmentServiceAllocationList(value.id);
  const createShipmentServiceAllocationQuery = useCreateShipmentServiceAllocation();
  const deleteShipmentServiceAllocationQuery = useDeleteShipmentServiceAllocation(value.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    isShowShipment && focusRef.current['shipmentSearch'].focus();
  }, [isShowShipment]);

  useEffect(() => {
    if (getShipmentServiceAllocationListQuery.data) {
      setShipmentsRows(getShipmentServiceAllocationListQuery.data);
    }
  }, [getShipmentServiceAllocationListQuery.data]);

  const shipmentsColumns: GridColDef[] = [
    {
      field: 'shipmentId',
      headerName: 'ID',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.shipmentId}</SmallLabel>
    },
    {
      field: 'source',
      headerName: 'From',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.source}</SmallLabel>
    },
    {
      field: 'destination',
      headerName: 'To',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.destination}</SmallLabel>
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 0.6,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{formatDate(params.row.createdAt)}</SmallLabel>
    },
    {
      field: 'createdBy',
      headerName: 'User',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.createdBy}</SmallLabel>
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      flex: 0.2,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => <Grid item xs={1}>
        <IconButton size="small" aria-label="delete" color="primary" onClick={() => onDeleteShipmentLink(params.row.id)}>
          <Delete fontSize="small" />
        </IconButton>
      </Grid>
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem
            data={[
              {
                title: 'ID',
                value: <SmallShipmentLabel>{row.shipmentId}</SmallShipmentLabel>
              },
              {
                title: 'Date',
                value: <SmallShipmentLabel>{formatDate(row.createdAt)}</SmallShipmentLabel>
              },
              {
                title: 'From',
                value: <SmallShipmentLabel>{row.source}</SmallShipmentLabel>
              },
              {
                title: 'User',
                value: <SmallShipmentLabel>{row.createdBy}</SmallShipmentLabel>
              },
              {
                title: 'To',
                value: <SmallShipmentLabel>{row.destination}</SmallShipmentLabel>
              },
              {
                title: 'Action',
                value: row.shipmentId && 
                  <StyledIconButton size="small" aria-label="delete" color="primary" onClick={() => onDeleteShipmentLink(row.shipmentId)}>
                    <Delete fontSize="small" />
                  </StyledIconButton>
              }
            ]}
          />
        );
      },
      sortable: false,
      filterable: false
    }
  ];

  const linkedServicesColumns: GridColDef[] = [
    {
      field: 'serviceTypeCode',
      headerName: 'Type',
      flex: 0.3,
      hide: isMobileView,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.serviceTypeCode}</SmallLabel>
    },
    {
      field: 'detail',
      headerName: 'Detail',
      flex: 0.5,
      hide: isMobileView,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.detail}</SmallLabel>
    }
  ];

  useEffect(() => {
    const linkedChilds: LinkedChildServiceData[] = [];
    linkedServiceValue?.childServices.map((item) => {
      const childItems = {
        id: item.id,
        hierarchy: item.hierarchy,
        serviceTypeCode: item.serviceTypeCode,
        detail: item.detail,
      };
      linkedChilds.push(childItems);
    });
    setLinkedChildServices(linkedChilds);
    setLinkedParentService(linkedServiceValue?.parentService);
  }, [linkedServiceValue]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLiveSearchForParent = () => {
    setIsShow(true);
    setIsChild(false);
    setSelectedParentServiceId(NaN);
  };

  const handleLiveSearchForChild = () => {
    setIsShow(true);
    setIsChild(true);
    setSelectedParentServiceId(NaN);
  };

  const handleParentServiceSave = async () => {
    setIsShow(false);
    const parentServiceUpdate: any = {
      id: value.id,
      parentServiceId: selectedParentServiceId,
      isChild: isChild
    };

    selectedParentServiceId && await updateParentServiceQuery.mutateAsync(parentServiceUpdate);
  };

  const handleLiveSearchForShipment = () => {
    setIsShowShipment(true);
  };

  const handleLinkShipmentSave = async (id: number, createdAt?: string) => {
    const shipmentLink: any = {
      shipmentId: id,
      serviceId: value.id,
      logType: LogTypeNames.ExistingShipmentLinked,
      createdDate: createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm') : ''
    };
    await createShipmentServiceAllocationQuery.mutateAsync(shipmentLink);
    setIsShowShipment(false);
    setLinkShipmentId('');
  };

  const onDeleteShipmentLink = async (id: number) => {
    await deleteShipmentServiceAllocationQuery.mutateAsync({
      id: id
    });
  };

  const onClickNewShipmentLink = () => {
    const { id, mainLocation, mainLocationNodeId } = value;
    if (isMobileView) {
      const dispatchURL = genarateNewLinkShipmentURL(id, mainLocation, mainLocationNodeId, true);
      window.open(dispatchURL,'_blank');
    } else {
      onDispatch({
        openFragment: true,
        serviceId: id,
        mainLocation,
        mainLocationNodeId,
        exited: false,
        isFromServiceEdit: true
      });
    }
  };

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  return (
    <div>
      <Accordion defaultExpanded={expandOnMobile} onChange={() => handleExpand()}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack spacing={2}>
            <Grid>
              <Typography>Reported Information</Typography>
            </Grid>
            {!isExpanded && (
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 8 }}>
                <Stack>
                  <Stack direction="row" spacing={13.5}>
                    <BoldLabel>Serial</BoldLabel>
                    <Label>{value.reportedSerialNumber}</Label>
                  </Stack>
                  <Stack direction="row" spacing={13}>
                    <BoldLabel>Model</BoldLabel>
                    <EditDropdownField
                      isEditable={false}
                      options={options.reportedModelOptions?.map((obj: any) => ({ 
                        label: obj.value?.clientModelName, 
                        value: obj.id.toString()
                      })) || []}
                      value={value.reportedModelId?.toString() || ''}
                      onChange={(val: any) => onChange({
                        ...value,
                        reportedModelId: val
                      })}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <BoldLabel>Fault/Description</BoldLabel>
                    <EditDropdownField
                      isEditable={false}
                      options={reportedFaultTypes(options.reportedFaultOptions || [])}
                      value={value.reportedFaultAndDescriptionId?.toString() || ''}
                      onChange={(val: any) => onChange({
                        ...value,
                        reportedFaultAndDescriptionId: val
                      })}
                    />
                  </Stack>
                </Stack>
                <Stack>
                  <Grid container>
                    <BoldLabel>Shipments [{shipmentsRows.length}]</BoldLabel>:&nbsp;
                    {shipmentsRows.map((shipment, index: number) => 
                      <Label key={index}>
                        {shipment.shipmentId}
                      </Label>
                    )}
                  </Grid>
                  <Stack direction="row">
                    <StyledLink 
                      href="javascript:void(0);" 
                      underline={UnderlineTypeProps.None} 
                      onClick={() => isUserHasPermission(ActionPermissions.Shipment_View_Dispatch, permissionList) && onClickNewShipmentLink()}
                    >
                      [+ New]
                    </StyledLink>
                    {!isShowShipment &&
                      <StyledLink href="javascript:void(0);" underline={UnderlineTypeProps.None} onClick={handleLiveSearchForShipment}>[- Link]</StyledLink>
                    }
                  </Stack>
                </Stack>
                <Stack>
                  <Grid container>
                    <BoldLabel>Linked Services [{linkedChildServices?.length}]</BoldLabel>:&nbsp;
                    {linkedChildServices.map((service, index: number) => 
                      <Label key={index}>
                        {service.id}
                      </Label>
                    )}
                  </Grid>
                  <Stack direction="row" spacing={5}>
                    <BoldLabel>{`Attachments [${attachmentRows.length}]`}</BoldLabel>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} width="100%">
            <Grid item xs={12} sm={12} md={4} width="100%">
              <Stack direction="row">
                <ItalicLabel>Logged Detail</ItalicLabel>
              </Stack>
              <Stack direction="row" spacing={9}>
                <BoldLabel>Service ID</BoldLabel>
                <Label>{value.id}</Label>
              </Stack>
              <Stack direction="row" spacing={11.5}>
                <BoldLabel>Logged</BoldLabel>
                <Label>{formatDate(value.createdAt)}</Label>
              </Stack>
              <Stack direction="row" spacing={19.3}>
                <p></p>
                <SmallItalicLabel>by {value.loggedBy}</SmallItalicLabel>
              </Stack>
              <Stack direction="row" spacing={4}>
                <BoldLabel>Reference No 1</BoldLabel>
                <EditField
                  isEditable={permissions.isReferenceNo1}
                  value={value.referenceNo1 || '-'}
                  onChange={(val) => onChange({
                    ...value,
                    referenceNo1: val
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={4}>
                <BoldLabel>Reference No 2</BoldLabel>
                <EditField
                  isEditable={permissions.isReferenceNo2}
                  value={value.referenceNo2 || '-'}
                  onChange={(val) => onChange({
                    ...value,
                    referenceNo2: val
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={4}>
                <BoldLabel>Reference No 3</BoldLabel>
                <EditField
                  isEditable={permissions.isReferenceNo3}
                  value={value.referenceNo3 || '-'}
                  onChange={(val) => onChange({
                    ...value,
                    referenceNo3: val
                  })}
                />
              </Stack>
              <Stack marginTop={3} />
              <Stack direction="row">
                <ItalicLabel>Reported Detail</ItalicLabel>
              </Stack>
              <Stack direction="row" spacing={13.5} width="100%">
                <BoldLabel>Serial</BoldLabel>
                <EditNodePartLiveSearch
                  title="Reported Serial Number"
                  isLocation={false}
                  isSerial={true}
                  serial={value.reportedSerialNumber || '-'}
                  isEditable={permissions.isReportedSerial}
                  onChange={(val, id) => {
                    onChange({
                      ...value,
                      reportedSerialNumberPartId: id || 0,
                      reportedSerialNumber: val
                    });
                  } }
                  searchLimit={15} 
                  isClientHierarchy={false}
                />
              </Stack>
              <Stack direction="row" spacing={13} mt={1} width="100%">
                <BoldLabel>Model</BoldLabel>
                <EditDropdownField
                  isEditable={permissions.isReportedModel}
                  options={options.reportedModelOptions?.map((obj: any) => ({ 
                    label: obj.value?.clientModelName, 
                    value: obj.id.toString(),
                    disabled: !obj.value?.enabled
                  })) || []}
                  value={value.reportedModelId?.toString() || '-1'}
                  onChange={(val: any) => onChange({
                    ...value,
                    reportedModelId: val
                  })}
                  optionalLabel="Select Model"
                  optionalLabelEnabled={true}
                />
              </Stack>
              <Stack direction="row" spacing={2} mt={1} width="100%">
                <BoldLabel>Fault/Description</BoldLabel>
                <EditDropdownField
                  isEditable={permissions.isReportedFault}
                  options={reportedFaultTypes(options.reportedFaultOptions || [])}
                  value={value.reportedFaultAndDescriptionId?.toString() || '-1'}
                  onChange={(val: any) => onChange({
                    ...value,
                    reportedFaultAndDescriptionId: val
                  })}
                  optionalLabel="Select Fault/Description"
                  optionalLabelEnabled={true}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <BoldLabel>Shipments [{shipmentsRows.length}]</BoldLabel>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                      <StyledLink 
                        href="javascript:void(0);" 
                        underline={UnderlineTypeProps.None} 
                        onClick={() => isUserHasPermission(ActionPermissions.Shipment_View_Dispatch, permissionList) && onClickNewShipmentLink()}
                      >
                        [+ New]
                      </StyledLink>
                    </Grid>
                    {isShowShipment ?
                      <Grid item ml={1}>
                        <Grid container alignItems="center" justifyContent="flex-end">
                          <Grid item width="200px">
                            <LiveSearchBox
                              title={'Shipment Search'}
                              timeOffset={400}
                              value={linkShipmentId || ''}
                              onClearValue={() => {
                                setLinkShipmentId('');
                              }}
                              renderItem={(props: any, option: any) => {
                                return (
                                  <LiveSearchListItem {...props}>
                                    <LinkShipmentSearchItem data={option} />
                                  </LiveSearchListItem>
                                );
                              }}
                              onChange={async (obj: LinkShipmentSearchResult) => {
                                setLinkShipmentId(`AM-SID-${obj.name}` || '');
                                handleLinkShipmentSave(obj.id, obj.createdAt);                        
                              }} 
                              onApiInvoke={async (obj) => {
                                return await searchLinkShipmentsQuery.mutateAsync({
                                  id: obj,
                                  clientHierarchy: value.clientHierarchy
                                });
                              }}
                              inputRef={(element: HTMLElement) => { focusRef.current['shipmentSearch' as keyof typeof focusRef] = element; }}
                            />
                          </Grid>
                          <Grid item>
                            <ClearIcon sx={{ color: `${COLORS.Red}` }} onClick={() => setIsShowShipment(false)}/>
                          </Grid>
                        </Grid>
                      </Grid> 
                      :
                      <Grid item>
                        <StyledLink href="javascript:void(0);" underline={UnderlineTypeProps.None} onClick={handleLiveSearchForShipment}>[- Link]</StyledLink>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <GridContainer isMobile={isMobileView} rowLength={shipmentsRows.length}>
                <CustomDataGrid
                  columns={shipmentsColumns}
                  rows={shipmentsRows}
                  getRowId={(row) => row?.id}
                  disableColumnFilter={true}
                  disableColumnSelector={true}
                  disableDensitySelector={true}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: false,
                      hideFooter: true
                    }
                  }}
                  isMobile={isMobileView}
                  enableCSVExport={false}
                  exportFileName={''}
                  getRowHeight={() => 'auto'}
                />
              </GridContainer>
              <Attachments
                rows={attachmentRows}
                onClick={onClick}
                isMobile={isMobileView}
                fileTypes={getMatchingMimeTypes(SERVICE_ATTACHMENTS_MIME_TYPES, ServiceAttachmentMimeTypes.ReportedInfo)}
                maxFileSize={2000}
                setFileDetails={setFileDetails}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Stack direction="row" justifyContent="space-between">
                {linkedChildServices.length !== 0 ? (<BoldLabel>Linked Services [{linkedChildServices.length}]</BoldLabel>) : <BoldLabel>Linked Services</BoldLabel>}
                {!isShow && <Grid>
                  <StyledLink href="javascript:void(0);" underline={UnderlineTypeProps.None} onClick={() => handleLiveSearchForParent()}>[- Link Parent]</StyledLink>
                  <StyledLink href="javascript:void(0);" underline={UnderlineTypeProps.None} onClick={() => handleLiveSearchForChild()}>[- Link Child]</StyledLink>
                </Grid>}
              </Stack>
              {!isShow && 
                <Grid>
                  <Typography>Parent : {linkedParentService && !!linkedParentService && <Link href={`/services/${linkedParentService?.targetServiceId}`}>{linkedParentService?.targetServiceId}</Link>}</Typography>
                </Grid>
              }
              {isShow && 
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={10} width="100%">
                    <LiveSearchBox
                      title={isChild ? 'Child Service Search' : 'Parent Service Search'}
                      timeOffset={400}
                      inputRef={(element: HTMLElement) => { focusRef.current['serviceSearch' as keyof typeof focusRef] = element; }}
                      value={isNaN(selectedParentServiceId) ? '' : selectedParentServiceId?.toString()}
                      onClearValue={() => {
                        setSelectedParentServiceId(NaN);
                      }}
                      renderItem={(props: any, option: any) => {
                        return (
                          <LiveSearchListItem {...props}>
                            <IdSearchItem data={option} />
                          </LiveSearchListItem>
                        );
                      }}
                      onChange={async (obj: ServiceData) => {
                        setSelectedParentServiceId(obj.id);
                      }}
                      onApiInvoke={async (obj) => {
                        return await searchServiceQuery.mutateAsync(obj);
                      }}
                      isError={locationSearchError}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} >
                    <Button variant={ButtonVariantProps.Primary} onClick={handleParentServiceSave}>Save</Button>
                  </Grid>
                </Grid>
              }
              {!!linkedChildServices.length && !!linkedChildServices[0].id && 
                <GridContainer isMobile={isMobileView} rowLength={linkedChildServices.length}>
                  <DataGrid
                    treeData
                    columns={linkedServicesColumns}
                    rows={linkedChildServices}
                    getTreeDataPath={getTreeDataPath}
                    getRowId={(row) => row?.id}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    hideFooter={true}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: false
                      }
                    }}
                    groupingColDef={{
                      headerName: isMobileView ? '' : 'ID',
                      hideDescendantCount: true,
                      valueFormatter: (params: GridValueFormatterParams<string | number>) => <SmallLabel><Link href={`/services/${params.value}`}>{params.value}</Link></SmallLabel>,
                      flex: 0.5
                    }}
                    getRowHeight={() => 'auto'}
                  />
                </GridContainer>
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};